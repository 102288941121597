<template>
  <div class="qrcodeAuthorizationDetail content">
    <!-- 用户申请 -->
    <div v-if="popupData.fkApplyid">
      <p class="title"><span class="sign"></span>申请信息</p>
      <div class="itemBox">
        <div class="itemCon">
          <p class="label">申请类型：</p>
          <p>{{ detaileData.applytypename }}</p>
        </div>
        <div class="itemCon">
          <p class="label">用途说明：</p>
          <p>{{ detaileData.applymemo }}</p>
        </div>
        <div class="itemCon">
          <p class="label">申请人：</p>
          <p>{{ detaileData.applyusername }}</p>
        </div>
        <div class="itemCon">
          <p class="label">申请时间：</p>
          <p>{{ detaileData.applytime }}</p>
        </div>
        <div class="button pass" v-if="detaileData.result == 2">审核通过</div>
        <div class="button noPass" v-else>审核不通过</div>
      </div>
      <p class="title"><span class="sign"></span>结果</p>
      <div class="itemBox">
        <div class="itemCon">
          <p class="label">视频名称：</p>
          <p>{{ detaileData.videoname }}</p>
        </div>
        <div class="itemCon">
          <p class="label">视频类型：</p>
          <p>{{ detaileData.typename }}</p>
        </div>
        <div class="itemCon" v-if="!detaileData.filecontent">
          <p class="label">有效时间：</p>
          <p class="red">长期有效</p>
        </div>
        <div class="itemCon" v-if="detaileData.filecontent">
          <p class="label">有效时间：</p>
          <p class="red">
            {{ detaileData.begintime }}至{{ detaileData.endtime }}
          </p>
        </div>
        <div class="itemCon" v-if="detaileData.filecontent">
          <p class="label">二维码图片：</p>
          <div class="qrcode">
            <img :src="detaileData.filecontent" />
            <p class="download">点击下载二维码图片</p>
          </div>
        </div>
      </div>
      <p class="title"><span class="sign"></span>审核信息</p>
      <div class="itemBox">
        <div class="itemCon">
          <p class="label">审核人：</p>
          <p>{{ detaileData.checkusername }}</p>
        </div>
        <div class="itemCon">
          <p class="label">审核时间：</p>
          <p>{{ detaileData.checktime }}</p>
        </div>
        <div class="itemCon">
          <p class="label">说明：</p>
          <p>{{ detaileData.checkview }}</p>
        </div>
      </div>
    </div>
    <!-- 直接授权 -->
    <div v-else>
      <p class="title"><span class="sign"></span>结果</p>
      <div class="itemBox">
        <div class="itemCon">
          <p class="label">视频名称：</p>
          <p>{{ detaileData.videoname }}</p>
        </div>
        <div class="itemCon">
          <p class="label">视频类型：</p>
          <p>{{ detaileData.typename }}</p>
        </div>
        <div class="itemCon red">
          <p class="label">二维码有效时间：</p>
          <p>
            {{ detaileData.begintime
            }}{{ detaileData.begintime && detaileData.endtime ? "至" : ""
            }}{{ detaileData.endtime }}
          </p>
        </div>
        <div class="itemCon">
          <p class="label">二维码图片：</p>
          <div class="qrcode">
            <img :src="detaileData.filecontent" />
            <p class="download">点击下载二维码图片</p>
          </div>
        </div>
      </div>
      <p class="title"><span class="sign"></span>授权信息</p>
      <div class="itemBox">
        <div class="itemCon">
          <p class="label">授权人：</p>
          <p>{{ detaileData.operateusername }}</p>
        </div>
        <div class="itemCon">
          <p class="label">授权时间：</p>
          <p>{{ detaileData.createtime }}</p>
        </div>
        <div class="itemCon">
          <p class="label">授权说明：</p>
          <p>{{ detaileData.memo }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "qrcodeAuthorizationDetail",
  props: {
    popupData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      detaileData: {}
    };
  },

  mounted() {
    console.log("popupData", this.popupData);
    // 直接授权详情
    if (this.popupData.fkApplyid) {
      this.getUserApplyById();
    } else {
      this.getDirectAuthById();
    }
  },
  methods: {
    getDirectAuthById() {
      const params = {
        pkId: this.popupData.id
      };
      this.$api("videoManage.getDirectAuthById")
        .invoke(params)
        .then(({ data: { data } }) => {
          this.detaileData = data;
        });
    },
    getUserApplyById() {
      const params = {
        pkId: this.popupData.id
      };
      this.$api("videoManage.getUserApplyById")
        .invoke(params)
        .then(({ data: { data } }) => {
          this.detaileData = data;
          console.log("data111", data);
        });
    }
  }
};
</script>
<style scoped lang="less">
.qrcodeAuthorizationDetail {
  padding: 12px;
  .title {
    color: #6294ff;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .sign {
      flex-shrink: 0;
      width: 0;
      height: 0;
      padding: 8px 2px;
      border-radius: 1px;
      margin-right: 8px;
      background: #6294ff;
    }
  }
  .itemBox {
    background: #f2f2f2;
    padding: 12px;
    margin-bottom: 15px;
    position: relative;
    .itemCon {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .label {
        width: 115px;
        text-align: right;
      }
      .qrcode {
        display: flex;
        align-items: center;
        flex-flow: column;
        img {
          width: 100px;
          height: 100px;
        }
        .download {
          cursor: pointer;
        }
      }
    }
    .button {
      position: absolute;
      right: 0;
      bottom: 0;
      padding: 10px 16px;
      border-radius: 3px;
      font-size: 14px;
    }
    .qrcode {
      // position: absolute;
      // right: 20px;
      // bottom: 7px;
      // display: flex;
      // flex-flow: column;
      // align-items: center;
      // img {
      //   width: 100px;
      //   height: 100px;
      // }
      // .download {
      //   cursor: pointer;
      // }
    }
    .pass {
      border: 1px solid #3fcc00;
      color: #3fcc00;
    }
    .noPass {
      border: 1px solid #f40000;
      color: #f40000;
    }
    .red {
      color: #f00f00;
      font-size: 14px;
    }
  }
}
</style>
